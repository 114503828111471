@import '../../../../scss/theme-bootstrap';

.always_artistry {
  .slick-track {
    display: flex;
  }
  .slick-list {
    padding: 0 10px;
  }
  .slick-slider {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  .slick-next,
  .slick-prev {
    margin-top: -11%;
    &.slick-disabled {
      &::before {
        opacity: 0;
      }
    }
  }
  &.carousel-mobile--peeking {
    .slick-list {
      padding: 0 15px;
    }
    .always_artistry__rendered {
      padding: 8px;
      @include breakpoint($medium-up) {
        padding: 15px 20px;
      }
    }
  }
  &__inner {
    position: relative;
  }
  &__heading {
    &.padding-default-wrapper {
      padding: 20px;
    }
    .content-block {
      &__line--header {
        margin-bottom: 15px;
        margin-#{$ldirection}: -2px;
        @include breakpoint($medium-up) {
          margin-bottom: 20px;
        }
      }
      &__line--content {
        .custom-text {
          line-height: 1.2;
        }
      }
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      position: relative;
    }
    &:not(.slick-initialized) {
      .slick-slide {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
  &:not(.default-always-artistry) {
    .always_artistry__rendered {
      picture {
        position: relative;
        display: block;
        &::before {
          content: '';
          display: block;
          padding-bottom: 120%;
          width: 100%;
          @include breakpoint($medium-up) {
            padding-bottom: 130%;
          }
        }
      }
      img {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__rendered {
    padding: 8px 10px;
    height: auto;
    @include breakpoint($medium-up) {
      padding: 15px 20px;
      &:first-child {
        grid-column: span 2;
        grid-row: span 2;
        width: 100%;
      }
    }
    .always-artistry-with-border & {
      @include breakpoint($medium-up) {
        border-#{$ldirection}: 1px solid $color-gray-op10;
        &:first-child {
          border-#{$ldirection}: none;
        }
        &:last-child {
          border-#{$rdirection}: none;
        }
        &:nth-child(4){
          border-#{$rdirection}: 1px solid $color-gray-op10;
        }
      }
    }
  }
  &.circle-square {
    &__top-left {
      .always_artistry__rendered {
        &:nth-child(2) {
          img {
            border-radius: 50%;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          picture {
            &::before {
              padding-bottom: 100%;
            }
          }
        }
      }
    }
    &__bottom-left {
      .always_artistry__rendered {
        &:nth-child(4) {
          img {
            border-radius: 50%;
          }
        }
        &:nth-child(4),
        &:nth-child(5) {
          picture {
            &::before {
              padding-bottom: 100%;
            }
          }
        }
      }
    }
  }
  &.square_all {
    .always_artistry__rendered {
      &:not(:first-child) {
        picture {
          &::before {
            padding-bottom: 100%;
          }
        }
      }
    }
  }
  &__carousel-dots {
    text-align: center;
    .slick-dots {
      transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
      width: 100%;
      bottom: 10px;
      button {
        height: 1px;
        opacity: 1;
        &::before {
          content: '';
          height: 1px;
          width: 12px;
          opacity: 1;
          background: $color-gray-on-light;
        }
      }
      .slick-active {
        button {
          &::before {
            height: 2px;
            background-color: $color-core-black;
          }
        }
      }
      li {
        height: 12px;
        width: 12px;
        margin: 0 4px;
      }
    }
  }
  .tout-block {
    &__content-above-media {
      .content-block__line--content {
        .custom-text {
          line-height: 1.2;
        }
      }
    }
    &__media-wrapper {
      justify-content: flex-end;
      @include breakpoint($landscape-up) {
        justify-content: normal;
      }
    }
    &__wrapper {
      .content-block__line {
        &--eyebrow {
          .style--bare {
            color: $color-core-black;
            line-height: 1.3;
            margin-bottom: 6px;
            font-size: 15px;
            letter-spacing: normal;
          }
        }
        &--header {
          .style--bare {
            margin-#{$ldirection}: 0;
          }
        }
        &--content {
          margin: 0;
        }
      }
    }
  }
}
